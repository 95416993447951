
import { defineComponent } from 'vue';
import AddLessonModal from '@/components/teacher/AddLessonModal.vue';
import LessonList from '@/components/common/LessonList.vue';
import { courseDetailsMixin } from '@/mixins/courseDetailsMixin';
import CourseDetailCard from '@/components/common/CourseDetailsCard.vue';
import { showError } from '@/sweetalert2/templates.ts';
import { Course, Lesson, Translation, errorPrepend } from '@/models';
import CourseTab from '@/components/teacher/CourseTab.vue';
import MemberModal from '@/components/teacher/MemberModal.vue';

export default defineComponent({
  components: {
    MemberModal,
    CourseTab,
    CourseDetailCard,
    LessonList,
    AddLessonModal
  },
  mixins: [courseDetailsMixin],
  methods: {
    async addLesson ($event: { lessonName: string, translations: string[][] }) {
      let data = null;
      try {
        data = await Course.addLesson(this.course, $event.lessonName);
      } catch (e) {
        await showError(this.$t("messages.no lesson add") + errorPrepend());
        return;
      }
      const properFormat = this.formatStringArray($event.translations);
      await this.setLessons();
      if ($event.translations.length !== 0) {
        try {
          await Translation.updateLessonTranslations(data.id, properFormat);
        } catch (e) {
          await showError(this.$t("messages.no add vocab") + errorPrepend());
        } finally {
          Lesson.downloadAudios(data.id).then().catch();
        }
      }
    },
    async setLessons () {
      await this.fetchData();
    },
    async addMember ($event: { users: [], klasse: number }) {
      const data = await Course.getCourseMembers(this.course.id);
      const memberIds = data.map(d => d.id);
      const students = memberIds.concat($event.users);
      const classes = [];
      if ($event.klasse !== -1) {
        classes.push($event.klasse);
      }
      await Course.addMembers(this.course.id, students, classes);
      (this.$refs.courseTab as typeof CourseTab).getCourseMembers(this.course.id);
    },
    updateModal () {
      (this.$refs.membermodal as typeof MemberModal).getStudents();
    }
  }
});
