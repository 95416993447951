import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-dd295b52")
const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["data-target"]
const _hoisted_3 = {
  class: "row justify-content-between p-3",
  role: "button"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  class: "progress w-50",
  style: {"height":"20px"}
}
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 list-group" }
const _hoisted_11 = {
  key: 0,
  class: "d-flex align-self-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentStatsList = _resolveComponent("StudentStatsList")!
  const _component_NoContent = _resolveComponent("NoContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "card-header p-1 pl-4 pr-4",
      id: "headingOne",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getBookLessons && _ctx.getBookLessons(...args))),
      "data-toggle": "collapse",
      "data-target": '#collapse'+_ctx.index,
      style: {"min-height":"4rem"}
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.lesson.lesson), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            id: _ctx.lesson.name,
            class: _normalizeClass(_ctx.getLessonStyle(_ctx.lesson.allStudentsProgress)),
            role: "progressbar",
            style: _normalizeStyle({width: _ctx.lesson.allStudentsProgress+'%'})
          }, _toDisplayString(_ctx.roundProgress(_ctx.lesson.allStudentsProgress)) + "%", 15, _hoisted_6)
        ])
      ])
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'collapse'+_ctx.index,
      class: "collapse",
      "aria-labelledby": "headingOne",
      "data-parent": "#bookTemplates"
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_StudentStatsList, {
              students: _ctx.lesson.students
            }, null, 8, ["students"])
          ]),
          (_ctx.lesson.students.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_NoContent, {
                  text: _ctx.$t('messages.no students course')
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 8, _hoisted_7)
  ]))
}