
import { defineComponent, PropType } from 'vue';
import StudentStatsList from '@/components/teacher/StudentStatsList.vue';
import NoContent from '@/components/common/NoContent.vue';
import { Statistics } from '@/models/Statistics';

export default defineComponent({
  name: 'CourseStatistics',
  components: { NoContent, StudentStatsList },
  props: {
    lesson: {
      type: Object as PropType<Statistics>,
      required: true
    },
    course: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    roundProgress (progress: number) {
      const prog = progress.toFixed(2);
      return prog;
    },
    getLessonStyle (progress: number) {
      if (progress > 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-success';
      } else if (progress > 30 && progress <= 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-warning';
      } else {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-danger';
      }
    }
  }
});
