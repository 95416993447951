import { Course, Lesson } from '@/models';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { showError } from '@/sweetalert2/templates.ts';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export const courseDetailsMixin = defineComponent({
  data () {
    return {
      course: {} as Course,
      lessons: [] as Lesson[]
    };
  },
  computed: {
    ...mapState({ courses: 'courses' })
  },
  async created () {
    this.$watch(
      () => this.$route,
      (newVal: RouteLocationNormalizedLoaded, oldVal?: RouteLocationNormalizedLoaded) => {
        if (oldVal === undefined || newVal.name === oldVal.name) { this.fetchData(); }
      },
      { immediate: true }
    );
  },
  methods: {
    formatStringArray (translations: string[][]): { vocabFrom: string; vocabTo: string }[] {
      const properFormat: {vocabFrom: string, vocabTo: string}[] = [];
      translations.forEach(t => properFormat.push({ vocabFrom: t[0], vocabTo: t[1] }));
      return properFormat;
    },
    async fetchData () {
      const id = parseInt(this.$route.params.id.toString()) as number;
      this.course.id = id;
      try {
        this.course = await Course.getById(id);
        const lessons = await Lesson.getFromCourse(id);
        this.lessons = lessons.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        await showError(Course.LOAD_ERROR);
      }
    }
  }
});
