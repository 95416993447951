
import { defineComponent, PropType } from 'vue';
import MemberListItem from '@/components/teacher/MemberListItem.vue';
import { User } from '@/models';

export default defineComponent({
  name: 'MemberList',
  components: { MemberListItem },
  props: {
    members: {
      type: Object as PropType<User[]>,
      required: true
    },
    courseId: Number
  },
  emits: ['memberRemoved'],
  methods: {
    async memberRemoved () {
      this.$emit('memberRemoved');
    }
  },
  computed: {
    sortedMembers () {
      return [...this.members].sort((a, b) => a.lastname.localeCompare(b.lastname));
    }
  }
});
