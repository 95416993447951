
import { defineComponent, PropType } from 'vue';
import { StudentStats } from '@/models/Statistics';

export default defineComponent({
  name: 'StudentStatsList',
  props: {
    students: {
      type: Object as PropType<StudentStats[]>,
      required: true
    }
  },
  methods: {
    roundProgress (progress: number) {
      return progress.toFixed(2);
    },
    getLessonStyle (progress: number) {
      if (progress > 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-success';
      } else if (progress > 30 && progress <= 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-warning';
      } else {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-danger';
      }
    }
  }
});
