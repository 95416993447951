
import { defineComponent, PropType } from 'vue';
import { Course } from '@/models';

export default defineComponent({
  props: {
    course: {
      required: true,
      type: Object as PropType<Course>
    }
  }
});
