
import { defineComponent, PropType } from 'vue';
import { Book, Lesson, errorPrepend } from '@/models';
import BookCover from '@/components/admin/BookCover.vue';
import { showError, showSuccess } from '@/sweetalert2/templates.ts';
import { languageMixin } from '@/mixins/languageMixin';
import NoContent from '@/components/common/NoContent.vue';

export default defineComponent({
  name: 'BookTemplate',
  components: { NoContent, BookCover },
  mixins: [languageMixin],
  props: {
    book: {
      type: Object as PropType<Book>,
      required: true
    },
    course: {
      type: Number,
      required: true
    }
  },
  emits: ['templateInserted'],
  data () {
    return {
      bookLessons: [] as Lesson [],
      bookFlag: '' as string,
      lessonIds: [] as number [],
      bookImageUrl: '' as string,
      collapse: true
    };
  },
  async mounted () {
    const lang = this.shortToFull(this.book.languageTo);
    this.bookImageUrl = lang?.imageUrl ?? '';
  },
  methods: {
    async getBookLessons () {
      this.bookLessons = await Book.getLessons(this.book.id);
      this.bookLessons.sort((a, b) => a.name.localeCompare(b.name));
    },
    useBookTemplate: async function (book: boolean) {
      if (book) {
        await this.getBookLessons();
        this.lessonIds = this.bookLessons.map(l => l.id);
      }
      try {
        await Lesson.insertLessonTemplate(this.course, this.lessonIds);
        await showSuccess(this.$t("messages.template add course"));
      } catch (e) {
        await showError(this.$t("messages.error add template") + errorPrepend());
      }

      this.$emit('templateInserted');
    }
  }
});
