
import { defineComponent } from 'vue';
import { csvMixin } from '@/mixins/csvFile';
import { Class } from '@/models/Class';
import { Course, Student, User } from '@/models';

export default defineComponent({
  data () {
    return {
      users: [] as User[],
      class: 0,
      students: [] as Student[],
      classes: [] as Class [],
      courseMember: [] as Student[]
    };
  },
  props: {
    courseId: {
      type: Number,
      required: true
    }
  },
  mixins: [csvMixin],
  emits: ['addMember'],
  methods: {
    async addMember () {
      let classToAdd = this.class;
      if (this.users.length > 0) { classToAdd = -1; }
      this.$emit('addMember', { users: this.users, klasse: classToAdd });
      (this.$refs.dismissButton as HTMLButtonElement).click();
      this.students = [];
      this.class = 0;
      this.users = [];
    },
    async getStudents () {
      if (this.class === 0) { return; }
      this.students = await Class.getAllStudents(this.class);
      this.students = this.students.sort((a, b) => a.lastname.localeCompare(b.lastname));
      this.courseMember = await Course.getCourseMembers(this.courseId);
    },
    checkMember (student: Student) {
      return this.courseMember.filter(m => m.id === student.id).length > 0;
    }
  },
  async mounted () {
    this.classes = await Class.getAll();
    this.courseMember = await Course.getCourseMembers(this.courseId);
  }
});
