import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "progress w-50",
  style: {"height":"20px"}
}
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (student, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: "list-group-item list-group-item-action d-flex justify-content-between align-items-center"
    }, [
      _createTextVNode(_toDisplayString(student.name) + " ", 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          id: student.name,
          class: _normalizeClass(_ctx.getLessonStyle(student.progress)),
          role: "progressbar",
          style: _normalizeStyle({width: student.progress+'%'})
        }, _toDisplayString(_ctx.roundProgress(student.progress)) + "%", 15, _hoisted_2)
      ])
    ]))
  }), 128))
}