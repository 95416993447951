
import { defineComponent, PropType } from 'vue';
import { Book, Course, Lesson, User } from '@/models';
import BookTemplate from '@/components/teacher/BookTemplate.vue';
import MemberList from '@/components/teacher/MemberList.vue';
import CourseStatistics from '@/components/teacher/CourseStatistics.vue';
import { Statistics } from '@/models/Statistics';
import NoContent from '@/components/common/NoContent.vue';

export default defineComponent({
  components: { NoContent, CourseStatistics, MemberList, BookTemplate },
  data () {
    return {
      lessonsCopy: [] as Lesson [],
      bookTemplates: [] as Book [],
      courseMembers: [] as User [],
      courseStats: [] as Statistics []
    };
  },
  props:
    {
      course: {
        type: Object as PropType<Course>,
        required: true
      }
    },
  name: 'CourseTab',
  emits: ['reloadLessonList', 'reloadedCourseMembers'],
  methods: {
    async getCourseMembers (courseId: number) {
      this.courseMembers = await Course.getCourseMembers(courseId);
      this.$emit('reloadedCourseMembers');
    },
    async getBookTemplates () {
      this.bookTemplates = await Book.getAll();
      this.bookTemplates = this.bookTemplates.sort((a, b) => a.name.localeCompare(b.name));
      this.bookTemplates = this.bookTemplates.filter(b => b.languageFrom === this.course.languageFrom && b.languageTo === this.course.languageTo);
    },
    async getCourseStatistics (courseId: number) {
      this.courseStats = await Statistics.getCourseStatistics(courseId);
    },
    reloadLessonList () {
      this.$emit('reloadLessonList');
    }
  },
  computed: {
    lessons (): Lesson[] {
      /* @ts-expect-error : weis so is */
      return this.$parent?.$data.lessons;
    }
  },
  watch: {
    lessons: {
      handler (newLessons: Lesson[]) {
        this.lessonsCopy = newLessons;
      },
      deep: true
    }
  },
  async created () {
    this.$watch(
      () => this.course,
      (newVal: Course) => {
        this.getCourseMembers(newVal.id);
        this.getCourseStatistics(newVal.id);
        this.getBookTemplates();
      },
      { immediate: true }
    );
  }
});
