import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-bf80cc68")
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-2 d-flex align-items-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-5 d-flex" }
const _hoisted_6 = {
  style: {"color":"#4680ff"},
  class: "d-flex align-items-center"
}
const _hoisted_7 = { class: "col-4 d-flex justify-content-end align-items-center" }
const _hoisted_8 = { class: "col-1 d-flex align-items-center" }
const _hoisted_9 = {
  key: 0,
  class: "fas fa-chevron-down fa-2x"
}
const _hoisted_10 = {
  key: 1,
  class: "fas fa-chevron-left fa-2x"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "card-body pt-4 pb-2 pr-4" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-3 d-flex align-items-center" }
const _hoisted_15 = { class: "col-5" }
const _hoisted_16 = ["value", "id"]
const _hoisted_17 = ["for"]
const _hoisted_18 = { class: "col-4 text-right" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_NoContent = _resolveComponent("NoContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "card-header p-1 pl-4 pr-4",
      id: "headingOne",
      onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.getBookLessons(); _ctx.collapse = !_ctx.collapse})
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "rounded-circle languageImg",
            src: _ctx.bookImageUrl,
            alt: `${_ctx.languageToGerman(_ctx.book.languageTo)}`
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.book.name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.useBookTemplate(true)), ["stop"])),
            type: "button",
            class: "btn btn-primary"
          }, _toDisplayString(_ctx.$t("messages.Apply template")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          (!_ctx.collapse)
            ? (_openBlock(), _createElementBlock("i", _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.collapse)
            ? (_openBlock(), _createElementBlock("i", _hoisted_10))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (!_ctx.collapse)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_BookCover, { book: _ctx.book }, null, 8, ["book"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookLessons, (lesson) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: lesson.id,
                        class: "form-group pr-3"
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.lessonIds = $event)),
                          value: lesson.id,
                          class: "form-check-input",
                          id: lesson.id
                        }, null, 8, _hoisted_16), [
                          [_vModelCheckbox, _ctx.lessonIds]
                        ]),
                        _createElementVNode("label", {
                          class: "form-check-label label",
                          for: lesson.id
                        }, _toDisplayString(lesson.name), 9, _hoisted_17)
                      ]))
                    }), 128)),
                    (!_ctx.bookLessons.length)
                      ? (_openBlock(), _createBlock(_component_NoContent, {
                          key: 0,
                          text: _ctx.$t('messages.no lesson')
                        }, null, 8, ["text"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.useBookTemplate(false))),
                      class: "btn btn-primary"
                    }, _toDisplayString(_ctx.$t("messages.insert lessons")), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}