import { errorPrepend } from '@/models';
import axios from 'axios';
import i18n from '@/i18n';

export class Statistics {
  static LOAD_ERROR = i18n.global.t('messages.error load statistics') + errorPrepend();

  constructor (
    public courseProgress: number,
    public lessons: {
      name: string,
      progress: number,
      translations: number
    }[]
  ) {
  }

  static async getCourseStatistics (courseId: number): Promise<Statistics[]> {
    const { data } = await axios.get(`api/teacher/course/${courseId}/stats`);
    return data;
  }
}

export class StudentStats {
  constructor (
    public name: string,
    public progress: number
  ) {
  }
}
