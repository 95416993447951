import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-69c63cc4")
const _hoisted_1 = { class: "position-relative p-0" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      id: "langFrom",
      src: _ctx.getImgUrl(_ctx.course.languageFrom),
      alt: " "
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      id: "langTo",
      src: _ctx.getImgUrl(_ctx.course.languageTo),
      alt: _ctx.$t('messages.No picture available')
    }, null, 8, _hoisted_3)
  ]))
}