
import CourseDetailsFlags from '@/components/common/CourseDetailsFlags.vue';
import CourseInformation from '@/components/common/CourseInformation.vue';
import { PropType } from 'vue';
import { Course } from '@/models';

export default {
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    }
  },
  name: 'CourseDetailCard',
  components: { CourseDetailsFlags, CourseInformation }
};
