import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6ab570ad")
const _hoisted_1 = { id: "description" }
const _hoisted_2 = {
  key: 0,
  class: "text-wrap"
}
const _hoisted_3 = {
  key: 1,
  class: "text-wrap text-gray"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.$t("messages.name")), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.course.name), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t("messages.Lehrer")), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.course.teacher), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t("messages.Description:")), 1),
    (_ctx.course.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.course.description), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("messages.no desc")), 1))
  ]))
}