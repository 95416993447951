import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "align-items-center justify-content-center row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CourseDetailsFlags = _resolveComponent("CourseDetailsFlags")!
  const _component_CourseInformation = _resolveComponent("CourseInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CourseDetailsFlags, {
      class: "col-12 col-sm-5 mr-sm-3",
      course: $props.course
    }, null, 8, ["course"]),
    _createVNode(_component_CourseInformation, {
      class: "col-12 col-sm-7 mt-3 mt-sm-0 bg-white shadow align-items-center p-4 roundy",
      course: $props.course
    }, null, 8, ["course"])
  ]))
}