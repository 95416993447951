
import { defineComponent, PropType } from 'vue';
import { Course, User } from '@/models';

export default defineComponent({
  name: 'MemberListItem',
  data () {
    return {
      membersCopy: [] as User []
    };
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<User>
    },
    index: Number,
    courseId: {
      required: true,
      type: Number
    },
    members: {
      required: true,
      type: Object as PropType<User[]>
    }
  },
  emits: ['memberRemoved'],
  methods: {
    async removeMember () {
      this.membersCopy = [...this.members];
      const index = this.membersCopy.indexOf(this.member, 0);
      this.membersCopy.splice(index, 1);
      await Course.removeMember(this.membersCopy, this.courseId);
      this.$emit('memberRemoved');
      return true;
    }
  }
});
